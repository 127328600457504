:root { 
  --black: #000000;
  --cararra: #ebebeb;
  --celeste: #cccccc;
  --granite-gray: #666666;
  --gray: #7f7f7f;
  --international-orange: #fe5000;
  --licorice: #151515;
  --mercury: #e5e5e5;
  --mine-shaft: #2f2f2f;
  --mine-shaft-2: #333333;
  --onyx: #373a36;
  --pot-pourri: #eee7e2;
  --thunder: #2e2e2e;
  --white: #ffffff;
  --white-2: #ffffff26;
 
  --font-size-l: 19px;
  --font-size-l2: 16px;
  --font-size-m2: 24px;
  --font-size-s2: 12px;
  --font-size-xs2: 14px;
  --font-size-xxl: 30px;
  --font-size-xxxl: 64px;
 
  --font-family-helveticaneue-bold: "HelveticaNeue-Bold", Helvetica;
  --font-family-helveticaneue-medium: "HelveticaNeue-Medium", Helvetica;
  --font-family-inter: "Inter", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
  --font-family-proxima_nova-semibold: "Proxima Nova-Semibold", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
}
