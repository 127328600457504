@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Inter:400,700,500|Poppins:400|Roboto:600");
@font-face {
  font-family: "HelveticaNeue-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/HelveticaNeue-Bold") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("https://fonts.animaapp.com/HelveticaNeue-Medium") format("opentype");
}
@font-face {
  font-family: "Proxima Nova-Semibold";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/ProximaNova-Semibold") format("opentype");
}

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}

body{
  position: absolute;
}
